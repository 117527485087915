import { makeStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    SelectInput,
    ImageField,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    FileInput,
    FileField,
    required,
    number,
    DateTimeInput,
    useGetList,
} from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { DisplayStatus } from "../../enums/display-status.enum";
import { validateDisplayStatus } from "../../services";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const displayOptions = Object.keys(DisplayStatus).map(key => ({
    id: DisplayStatus[key],
    name: key,
}));

export const PathCreate = (props: any) => {
    const timePeriods = useGetList<TimePeriod>(
        "timeperiods",
        { page: 1, perPage: 100 },
        { field: "id", order: "ASC" },
        {}
    );
    const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
    const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
    const selectedTimePeriod = GetTimePeriodRange(
        selectedTimePeriodId,
        timePeriods.data || {}
    );

    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none" validate={validateDisplayStatus}>
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="number" validate={[required()]}/>
                <TextInput source="length" label="Length (km)" validate={[required(), number()]}/>
                <TextInput source="start" label="start name" />
                <TextInput source="end" label="end name" />
                <DateTimeInput source="groomedAt" defaultValue={new Date()} />
                <SelectInput source="difficulty" validate={[required()]} choices={[
                    { id: 'veryEasy', name: 'very easy' },
                    { id: 'easy', name: 'easy' },
                    { id: 'medium', name: 'medium' },
                    { id: 'hard', name: 'hard' },
                ]} />
                <SelectInput source="status" validate={[required()]} initialValue={'open'} choices={[
                    { id: 'open', name: 'open' },
                    { id: 'closed', name: 'closed' },
                    // { id: 'freshlyGroomed', name: 'freshly groomed' },
                ]} />
                <ReferenceInput label="Category" source="pathCategoryId" reference="pathcategories">
                    <SelectInput optionText="name" validate={[required()]}/>
                </ReferenceInput>
                <ReferenceArrayInput
                    label="Areas"
                    source="areas"
                    reference="areas"
                    target="id"
                    perPage={9999}
                    parse={ids => ids?.map(id => ({ id }))}
                    format={areas => areas?.map(b => b.id)}
                >
                    <SelectArrayInput optionText="name" validate={[required()]}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    label="Related Pois"
                    source="pois"
                    reference="pois"
                    target="id"
                    perPage={9999}
                    parse={ids => ids?.map(id => ({ id }))}
                    format={pois => pois?.map(b => b.id)}
                >
                    <SelectArrayInput optionText={record => {
                        console.log('record', record);
                        if (record.areas.length > 0) {
                            return `${record.name} (${record.areas.map(area => area.name).join(', ')})`;
                        }
                        return record.name;
                    }} validate={[required()]}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput label="Tags" source="tag_ids" reference="tags" allowEmpty>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <FileInput source="gpx" label="GPX file" accept=".gpx" validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <SelectInput
                    source="display"
                    choices={displayOptions}
                    defaultValue={DisplayStatus.Display}
                    onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
                    validate={[required()]}
                ></SelectInput>
                {displayValue === DisplayStatus.Scheduled && (
                    <ReferenceInput
                        label="Time period"
                        source="timePeriodId"
                        reference="timeperiods"
                        perPage={9999}
                        onChange={(e) => setSelectedTimePeriodId(e.target.value)}
                    >
                        <SelectInput
                            optionText="name"
                            helperText={selectedTimePeriod}
                        ></SelectInput>
                    </ReferenceInput>
                )}
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="start_en" label="Start eng" fullWidth />
                    <TextInput source="end_en" label="End eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline fullWidth />
                </TranslationInputs>
            </SimpleForm>
        </Create>
    );
}