import * as React from "react";
import { Create, TextInput, ImageInput, ImageField, required, BooleanInput, DateInput, SimpleForm } from 'react-admin';
import CustomToolbar from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";

export const PointOfInterestCategoryCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
                <BooleanInput source="isListed" validate={[required()]} fullWidth />
                <TextInput source="name" validate={[required()]} fullWidth />
                <TextInput source="pageTitle" validate={[required()]} fullWidth />
                <TextInput source="description" rows={8} multiline fullWidth />
                <ImageInput source="media" multiple={false}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="pageTitle_en" label="Page Title eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline fullWidth />
                </TranslationInputs>
                <DateInput disabled source="createdAt" fullWidth />
                <DateInput disabled source="updatedAt" fullWidth />
            </SimpleForm>
        </Create>
    );
}