import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    required,
    number,
    AutocompleteArrayInput,
} from 'react-admin';
import TranslationInputs from "../../../components/translationInputs/translationInputs.component";

export const TrailCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none">
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="length" label="Length (km)" validate={[required(), number()]}/>
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <ReferenceArrayInput label="Sub Paths" reference="snowmobile/subpaths" source="subpath_ids" validate={[required()]}>
                    <AutocompleteArrayInput suggestionLimit={10} />
                </ReferenceArrayInput>
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline={true} fullWidth={true} />
                </TranslationInputs>
            </SimpleForm>
        </Create>
    );
}