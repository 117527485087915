import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../../components/translationInputs/translationInputs.component";
import { Status } from "../../../shared/enums/status.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const FraEdit = (props: any) => {
  const classes = useStyles();

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));

  return (
    <Edit {...props}>
      <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <SelectInput
          source="status"
          validate={[required()]}
          initialValue={"open"}
          choices={statusOptions}
        />
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput
          source="geoJsonFilename"
          label="Current GeoJson file"
          disabled
        />
        <FileInput source="geojson" label="New Geo-Json file" accept=".geojson">
          <FileField source="src" title="title" />
        </FileInput>
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="fullUrl" title="title" />
        </ImageInput>
        <TranslationInputs {...props}>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
      </SimpleForm>
    </Edit>
  );
};
