import * as React from "react";
import { Fragment } from "react";

const TranslationInputs = ({ basePath, record, resource, children, variant, ...props }) => (
    <Fragment>
        <h3>English version</h3>
        {React.Children.map(children,
            child => {
                return React.cloneElement(child,
                    { variant: variant ?? 'outlined' }, null);
            })}
    </Fragment>
);

export default TranslationInputs;
