import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Edit, SimpleForm, TextInput, DateInput, required, AutocompleteArrayInput, ReferenceArrayInput, SelectInput, FormDataConsumer } from 'react-admin';
import { CustomToolbar } from './../../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const FilterEdit = (props: any) => {
    const classes = useStyles();

    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar/>}>
                <TextInput source="name" validate={[required()]} fullWidth/>
                <SelectInput label="Group Type" source="filterType" validate={[required()]} initialValue={'path'} fullWidth choices={[
                    { id: 'path', name: 'Cross-country skiing' },
                    { id: 'subPath', name: 'Snowmobile' },
                ]} />
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.filterType === 'path' &&
                        <ReferenceArrayInput label="Paths" reference="grooming/paths" source="path_ids" fullWidth>
                        <AutocompleteArrayInput suggestionLimit={10} variant="outlined"/>
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.filterType === 'subPath' &&
                        <ReferenceArrayInput label="Snowmobile Sub Paths" reference="grooming/snowmobile/subpaths" source="subPath_ids" fullWidth>
                        <AutocompleteArrayInput suggestionLimit={10} variant="outlined"/>
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}