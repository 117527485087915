import * as React from "react";
import { Edit, ImageInput, ImageField, TextInput, DateInput, SaveButton, FormWithRedirect, Toolbar, required, SimpleForm } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';

export const PathCategoryEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar noDelete={true} />}>
                <TextInput source="name" validate={[required()]} fullWidth />
                <TextInput source="pageTitle" validate={[required()]} fullWidth />
                <TextInput source="description" rows={8} multiline fullWidth />
                <ImageInput source="media" multiple={false}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="pageTitle_en" label="Page Title eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline fullWidth />
                </TranslationInputs>
                <DateInput disabled source="createdAt" fullWidth />
                <DateInput disabled source="updatedAt" fullWidth />
            </SimpleForm>
        </Edit>
    );
}

/*
export const PathCategoryEdit = (props: any) => {
    const classes = useStyles();

    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar/>}>
                <TextInput source="name"/>
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />

                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}
*/
