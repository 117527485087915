import * as React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { AoiEdit } from "./aoi.edit.component";
import { startCase, toLower } from "lodash";
import { DisplayStatus } from "../../enums/display-status.enum";

const AoiFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
    <ReferenceInput
      label="Category"
      source="areaOfInterestCategoryId"
      target="id"
      reference="aoicategories"
      allowEmpty={false}
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const AoiList = (props: any) => {
  const getDisplay = (record) => {
    if (record.display === DisplayStatus.Scheduled && record.timePeriod) {
      return `${startCase(toLower(record.display))} (${
        record.timePeriod.name
      })`;
    }
    return startCase(toLower(record.display));
  };

  return (
    <List {...props} filters={<AoiFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={AoiEdit}>
        <TextField source="name" />
        <TextField
          source="areaOfInterestCategory.name"
          label="Category"
          sortable={false}
        />

        <ArrayField source="areas" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField color="secondary" source="name" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Display"
          render={(record) => getDisplay(record)}
        />
      </Datagrid>
    </List>
  );
};
