import { Toolbar, SaveButton, DeleteWithUndoButton } from "react-admin";
import { withStyles } from "@material-ui/core";
import React from "react";
import BackButton from "../buttons/backButton.component";

const toolbarStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export const CustomToolbar = withStyles(toolbarStyles)((props: any) => (
  <Toolbar {...props}>
    {(props as any).noRedirect ? (
      <SaveButton redirect={false} />
    ) : (
      <SaveButton />
    )}

    {/*<BackButton>Back</BackButton>*/}
    {!(props as any).noDelete && <DeleteWithUndoButton />}
  </Toolbar>
));

export default CustomToolbar;
