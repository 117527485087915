import * as React from "react";
import { Filter, TextInput } from 'react-admin';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { TagEdit } from "./tag.edit.component";

const TagListFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable />
    </Filter>
);

export const TagList = (props: any) => {
    return (
        <List
            {...props}
            filters={<TagListFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={TagEdit}>
                <TextField source="name" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}