import * as React from "react";
import {
  ArrayField,
  ChipField,
  Filter,
  FunctionField,
  SingleFieldList,
  TextInput,
} from "react-admin";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { InformationEdit } from "./information.edit.component";
import { startCase } from "lodash";
import { MapConnection } from "./enums/map-connection.enum";

const InformationListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
  </Filter>
);

export const InformationList = (props: any) => {
  const getMapConnection = (record) => {
    if (record.mapConnection === MapConnection.None) {
      return "—";
    }
    if (record.mapConnection === MapConnection.PointOfInterest) {
      return `POI (${record.pointOfInterest.name})`;
    }
    if (record.mapConnection === MapConnection.AreaOfInterest) {
      return `AOI (${record.areaOfInterest.name})`;
    }
  };

  return (
    <List {...props} filters={<InformationListFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={InformationEdit}>
        <TextField source="severity" />
        <TextField source="title" />
        <FunctionField
          label="Map connection"
          render={(record) => getMapConnection(record)}
        />
        <DateField
          source="startAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <DateField
          source="endAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <ArrayField source="tags" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField clickable={false} source="name" color={"primary"} />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Status"
          render={(record) => startCase(record.status)}
        />
      </Datagrid>
    </List>
  );
};
