import * as React from "react";
import { Fragment } from "react";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { BulkDeleteWithConfirmButton, Confirm, Filter, FunctionField, ReferenceInput, SelectInput, TextInput, useGetOne, useNotify, useRefresh, useUnselectAll, useUpdateMany } from 'react-admin';
import { List, Datagrid, TextField, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { PoiEdit } from "./poi.edit.component";
import { DisplayStatus } from "../../enums/display-status.enum";
import { startCase, toLower } from "lodash";

const PoiBulkActionsButtons = (props: any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isDisplayed, setIsDisplayed] = React.useState('');

    const [openDisplayConfirm, setOpenDisplayConfirm] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const [updateManyWithDisplay, { loading: loadingManyWithDisplay }] = useUpdateMany(
        'pois',
        selectedIds,
        { display: isDisplayed },
        {
            onSuccess: () => {
                refresh();
                notify('Pois updated');
                unselectAll('pois');
            },
            onFailure: error => notify(`Error: pois not updated with display: "${isDisplayed}".`, 'warning'),
        }
    );

    const handleDisplayDialogClose = () => setOpenDisplayConfirm(false);

    const handleConfirmForDisplay = () => {
        updateManyWithDisplay();
        setOpenDisplayConfirm(false);
    };

    React.useEffect(() => {
        setIsDisplayed('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleDisplayChange = (event: any) => {
        console.log('display');
        setOpenDisplayConfirm(true);
        setIsDisplayed(event.target.value);
    };

    return (
        <Fragment>
            <FormControl style={{ position: 'relative', top: '-8px', marginRight: '8px', minWidth: '120px' }}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">Set display</InputLabel>
                <Select
                    value={isDisplayed}
                    onChange={handleDisplayChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>Select One</em>
                    </MenuItem>
                    <MenuItem value="DISPLAY" selected>Display</MenuItem>
                    <MenuItem value="HIDE">Hide</MenuItem>
                </Select>
            </FormControl>
            <Confirm
                isOpen={openDisplayConfirm}
                loading={loadingManyWithDisplay}
                title="Update paths with display"
                content={`Are you sure you want set the display to "${isDisplayed}" for these items?`}
                onConfirm={handleConfirmForDisplay}
                onClose={handleDisplayDialogClose}
            />
            <BulkDeleteWithConfirmButton  {...props} />
        </Fragment>
    );
};

const PoiFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable />
        <ReferenceInput label="Area" source="areas" target="id" reference="areas" allowEmpty={false} alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="pointOfInterestCategoryId" target="id" reference="poicategories" allowEmpty={false} alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="display" allowEmpty={false} alwaysOn resettable choices={[
            { id: DisplayStatus.Display, name: 'Display' },
            { id: DisplayStatus.Hide, name: 'Hide' },
            { id: DisplayStatus.Scheduled, name: 'Scheduled'},
        ]} />
    </Filter>
);

export const PoiList = (props: any) => {
    const getDisplay = (record) => {
        if (record.display === DisplayStatus.Scheduled && record.timePeriod) {
            return `${startCase(toLower(record.display))} (${
                record.timePeriod.name
            })`;
        }
        return startCase(toLower(record.display));
    };

    return (
        <List
            {...props}
            bulkActionButtons={<PoiBulkActionsButtons />}
            filters={<PoiFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={PoiEdit}>
                <TextField source="name" />
                <TextField source="pointOfInterestCategory.name" label="Category" sortable={false} />
                <ArrayField
                    source="areas"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField clickable={false} source="name" color={'secondary'} />
                    </SingleFieldList>
                </ArrayField>
                <FunctionField
                    label="Display"
                    render={(record) => getDisplay(record)}
                />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}

