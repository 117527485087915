import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { Pagination, BulkDeleteWithConfirmButton, Button, Confirm, Filter, NullableBooleanInput, ReferenceInput, SelectInput, TextInput, useNotify, useRefresh, useUnselectAll, useUpdateMany } from 'react-admin';
import { List, Datagrid, TextField, DateField, BooleanField, ReferenceManyField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { SubPathEdit } from "./subPath.edit.component";

const PathBulkActionButtons = (props: any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isActive, setIsActive] = React.useState('');

    const [openStatusConfirm, setOpenStatusConfirm] = React.useState(false);
    const [openGroomedConfirm, setOpenGroomedConfirm] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const [updateManyWithStatus, { loading: loadingManyWithStatus }] = useUpdateMany(
        'snowmobile/subpaths',
        selectedIds,
        { status: isActive },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll('snowmobile/subpaths');
            },
            onFailure: error => notify(`Error: paths not updated with status: "${isActive}".`, 'warning'),
        }
    );

    const [updateManyAsGroomed, { loading: loadingManyAsGroomed }] = useUpdateMany(
        'snowmobile/subpaths',
        selectedIds,
        { groomedAt: new Date() },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll('snowmobile/subpaths');
            },
            onFailure: error => notify(`Error: paths failed to update groom.`, 'warning'),
        }
    );

    // const handleClick = () => setOpenStatusConfirm(true);
    const handleStatusDialogClose = () => setOpenStatusConfirm(false);

    const handleGroomedDialogClose = () => setOpenGroomedConfirm(false);

    const handleConfirmForStatus = () => {
        updateManyWithStatus();
        setOpenStatusConfirm(false);
    };

    const handleConfirmForGroomed = () => {
        updateManyAsGroomed();
        setOpenStatusConfirm(false);
    };

    React.useEffect(() => {
        setIsActive('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleChange = (event: any) => {
        console.log('status');
        setOpenStatusConfirm(true)
        setIsActive(event.target.value);
    };

    return (
        <Fragment>
            <FormControl style={{ position: 'relative', top: '-8px' }}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">Set status</InputLabel>
                <Select
                    value={isActive}
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>Select One</em>
                    </MenuItem>
                    <MenuItem value="open" selected>open</MenuItem>
                    <MenuItem value="closed">closed</MenuItem>
                    {/*<MenuItem value="freshlyGroomed">freshly groomed</MenuItem>*/}
                </Select>
                {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
            </FormControl>
            <Confirm
                isOpen={openStatusConfirm}
                loading={loadingManyWithStatus}
                title="Update paths with status"
                content={`Are you sure you want set the status to "${isActive}" for these items?`}
                onConfirm={handleConfirmForStatus}
                onClose={handleStatusDialogClose}
            />
            <Confirm
                isOpen={openGroomedConfirm}
                loading={loadingManyAsGroomed}
                title="Update paths as groomed"
                content={`Are you sure you want to groom these items?`}
                onConfirm={handleConfirmForGroomed}
                onClose={handleGroomedDialogClose}
            />
            <Button color="primary" variant="outlined" label="Groomed" onClick={() => setOpenGroomedConfirm(true)} />
            {/*<Button label="Save" onClick={handleClick} />*/}
            <BulkDeleteWithConfirmButton  {...props} />
        </Fragment>
    );
}

const PathFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable/>
        <ReferenceInput label="Area" source="areas" target="id" reference="areas" allowEmpty={false} alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" allowEmpty={false} alwaysOn resettable choices={[
            { id: 'open', name: 'open' },
            { id: 'closed', name: 'closed' },
        ]} />
        <SelectInput source="zone" allowEmpty={false} alwaysOn resettable choices={[
            { id: 'high', name: 'high' },
            { id: 'low', name: 'low' },
        ]} />
        {/*<Button variant="text" onClick={() => props.setFilters({})} label="Clear filters"/>*/}
    </Filter>
);

const PathPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const SubPathList = (props: any) => {
    return (
        <List
            {...props}
            perPage={25}
            pagination={<PathPagination />}
            bulkActionButtons={<PathBulkActionButtons />}
            filters={<PathFilter/>}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={SubPathEdit}>
                <TextField source="name" />
                <DateField source="groomedAt" options={{ day: '2-digit', hour: '2-digit', minute: '2-digit', month: 'short' }}/>
                <TextField source="status" />
                <TextField source="zone" />
                <ArrayField
                    source="areas"
                    fieldKey="id"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField color="secondary" source="name" />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
    );
}

export default SubPathList;
