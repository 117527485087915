import * as React from "react";
import { Filter, TextInput } from 'react-admin';
import { List, Datagrid, TextField, BooleanField, DateField } from 'react-admin';
import { AreaOfInterestCategoryEdit } from "./aoiCategory.edit.component";

const AreaOfInterestCategoryFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable />
    </Filter>
);

export const AreaOfInterestCategoryList = (props: any) => {
    return (
        <List
            {...props}
            filters={<AreaOfInterestCategoryFilter />}
            bulkActionButtons={false}
            exporter={false}
            actions={null}
        >
            <Datagrid rowClick="edit" expand={AreaOfInterestCategoryEdit}>
                <BooleanField source="isListed" label="Is Listed?" />
                <TextField source="name" />
                <TextField source="pageTitle" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}