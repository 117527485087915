import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    DateInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    required,
    number,
} from 'react-admin';
import TranslationInputs from "../../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from './../../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex',
    marginRight: '1rem' },
});

export const TrailEdit = (props: any) => {
    const classes = useStyles();

    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="length" label="Length (km)" validate={[required(), number()]}/>
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <ReferenceArrayInput label="Sub Paths" reference="snowmobile/subpaths" source="subpath_ids" validate={[required()]}>
                    <AutocompleteArrayInput optionText="name" suggestionLimit={10}  /*matchSuggestion={() => true}*//>
                </ReferenceArrayInput>
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline={true} fullWidth={true} />
                </TranslationInputs>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}