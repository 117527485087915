import * as React from "react";
import { Datagrid, Filter, List, TextField, TextInput } from "react-admin";
import { FraEdit } from "./fra.edit.component";

const FraFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
  </Filter>
);

export const FraList = (props: any) => {
  return (
    <List {...props} filters={<FraFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={FraEdit}>
        <TextField source="name" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};
