import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  DateInput,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";
import { startCase } from "lodash";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const TermsEdit = (props: any) => {
  const statusOptions = Object.keys(PublishingStatus).map((key) => {
    if (key === "published") {
      return {
        id: PublishingStatus[key],
        name: "Ready",
      };
    } else {
      return {
        id: PublishingStatus[key],
        name: startCase(key),
      };
    }
  });

  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar />}>
        <SelectInput
          source="status"
          validate={[required()]}
          choices={statusOptions}
        />
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput
          source="terms"
          rows={8}
          multiline={true}
          fullWidth={true}
          validate={[required()]}
        />

        <TranslationInputs {...props}>
          <TextInput
            source="title_en"
            label="Title eng"
            fullWidth
            validate={[required()]}
          />
          <TextInput
            source="terms_en"
            label="Terms eng"
            rows={8}
            multiline={true}
            fullWidth={true}
            validate={[required()]}
          />
        </TranslationInputs>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
