import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { BulkDeleteButton, BulkDeleteWithConfirmButton, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { List, Datagrid, TextField, EmailField, BooleanField, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { AreaEdit } from "./area.edit.component";

const AreaListFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable />
    </Filter>
);

export const AreaList = (props: any) => {
    return (
        <List
            {...props}
            filters={<AreaListFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={AreaEdit}>
                <TextField source="name" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}