import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, DateInput, SelectInput, required, email, minLength, regex } from 'react-admin';
import {CustomToolbar} from './../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const validatePhone = regex(/^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/, 'Must be a valid phone number format');

export const UserEdit = (props: any) => {
    const classes = useStyles();
    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar/>}>
                <BooleanInput source="isActive" />
                <TextInput disabled source="id" />
                {/*<ImageInput source="pictures" label="Related pictures" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" />
                </ImageInput>*/}
                <TextInput source="firstName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="lastName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="email" validate={[required(), email()]}/>
                <TextInput source="phone" validate={[required(), validatePhone]} />
                <SelectInput source="role" validate={[required()]} initialValue={'user'} choices={[
                    { id: 'user', name: 'User' },
                    { id: 'groomer', name: 'Groomer' },
                    { id: 'admin', name: 'Admin' },
                ]} />
                <TextInput source="password" label="New password" validate={[minLength(8)]}/>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock}/>
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </Edit>
    );
}