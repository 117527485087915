import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Create, SimpleForm, TextInput, required, SelectInput, AutocompleteArrayInput, ReferenceArrayInput, FormDataConsumer } from 'react-admin';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const FilterCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none">
                <TextInput source="name" validate={[required()]} fullWidth/>
                <SelectInput label="Group Type" source="filterType" validate={[required()]} initialValue={'path'} fullWidth choices={[
                    { id: 'path', name: 'Cross-country skiing' },
                    { id: 'subPath', name: 'Snowmobile' },
                ]} />
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.filterType === 'path' &&
                        <ReferenceArrayInput label="Paths" reference="grooming/paths" source="path_ids" fullWidth>
                        <AutocompleteArrayInput suggestionLimit={10} variant="outlined"/>
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.filterType === 'subPath' &&
                        <ReferenceArrayInput label="Snowmobile Sub Paths" reference="grooming/snowmobile/subpaths" source="subPath_ids" fullWidth>
                        <AutocompleteArrayInput suggestionLimit={10} variant="outlined"/>
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}