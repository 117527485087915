import * as React from 'react';
import { UserMenu as RAUserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

export const UserMenu = (props) => (
    <RAUserMenu {...props}>
        <MenuItemLink
            sidebarIsOpen={props.sidebarIsOpen}
            to="/configuration"
            primaryText="Configuration"
            leftIcon={<SettingsIcon />}
        />
    </RAUserMenu>
);

export default UserMenu;
