import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Pagination, BulkDeleteWithConfirmButton, Button, Confirm, Filter, ReferenceInput, SelectInput, TextInput, useNotify, useRefresh, useUnselectAll, useUpdateMany, FunctionField, useGetOne } from 'react-admin';
import { List, Datagrid, TextField, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { PathEdit } from "./path.edit.component";
import { DisplayStatus } from "../../enums/display-status.enum";
import { startCase, toLower } from "lodash";

const PathBulkActionButtons = (props: any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isActive, setIsActive] = React.useState('');
    const [isDisplayed, setIsDisplayed] = React.useState('');

    const [openStatusConfirm, setOpenStatusConfirm] = React.useState(false);
    const [openDisplayConfirm, setOpenDisplayConfirm] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const [updateManyWithStatus, { loading: loadingManyWithStatus }] = useUpdateMany(
        'paths',
        selectedIds,
        { status: isActive },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll('paths');
            },
            onFailure: error => notify(`Error: paths not updated with status: "${isActive}".`, 'warning'),
        }
    );

    const [updateManyWithDisplay, { loading: loadingManyWithDisplay }] = useUpdateMany(
        'paths',
        selectedIds,
        { display: isDisplayed },
        {
            onSuccess: () => {
                refresh();
                notify('Paths updated');
                unselectAll('paths');
            },
            onFailure: error => notify(`Error: paths not updated with display: "${isDisplayed}".`, 'warning'),
        }
    );

    const handleStatusDialogClose = () => setOpenStatusConfirm(false);

    const handleDisplayDialogClose = () => setOpenDisplayConfirm(false);

    const handleConfirmForStatus = () => {
        updateManyWithStatus();
        setOpenStatusConfirm(false);
    };

    const handleConfirmForDisplay = () => {
        updateManyWithDisplay();
        setOpenDisplayConfirm(false);
    };

    React.useEffect(() => {
        setIsActive('');
        setIsDisplayed('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleStatusChange = (event: any) => {
        console.log('status');
        setOpenStatusConfirm(true)
        setIsActive(event.target.value);
    };

    const handleDisplayChange = (event: any) => {
        console.log('display');
        setOpenDisplayConfirm(true)
        setIsDisplayed(event.target.value);
    };

    return (
        <Fragment>
            <FormControl style={{ position: 'relative', top: '-8px', marginRight: '16px', minWidth: '120px' }}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">Set status</InputLabel>
                <Select
                    value={isActive}
                    onChange={handleStatusChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>Select One</em>
                    </MenuItem>
                    <MenuItem value="open" selected>Open</MenuItem>
                    <MenuItem value="closed">Closed</MenuItem>
                    {/*<MenuItem value="freshlyGroomed">freshly groomed</MenuItem>*/}
                </Select>
                {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
            </FormControl>
            <Confirm
                isOpen={openStatusConfirm}
                loading={loadingManyWithStatus}
                title="Update paths with status"
                content={`Are you sure you want set the status to "${isActive}" for these items?`}
                onConfirm={handleConfirmForStatus}
                onClose={handleStatusDialogClose}
            />

            <FormControl style={{ position: 'relative', top: '-8px', marginRight: '8px', minWidth: '120px' }}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">Set display</InputLabel>
                <Select
                    value={isDisplayed}
                    onChange={handleDisplayChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>Select One</em>
                    </MenuItem>
                    <MenuItem value="DISPLAY" selected>Display</MenuItem>
                    <MenuItem value="HIDE">Hide</MenuItem>
                </Select>
            </FormControl>
            <Confirm
                isOpen={openDisplayConfirm}
                loading={loadingManyWithDisplay}
                title="Update paths with display"
                content={`Are you sure you want set the display to "${isDisplayed}" for these items?`}
                onConfirm={handleConfirmForDisplay}
                onClose={handleDisplayDialogClose}
            />
            <BulkDeleteWithConfirmButton  {...props} />
        </Fragment>
    );
}

const PathFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable/>
        <ReferenceInput label="Area" source="areas" target="id" reference="areas" allowEmpty={false} alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="pathCategoryId" target="id" reference="pathcategories" allowEmpty={false} alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" allowEmpty={false} alwaysOn resettable choices={[
            { id: 'open', name: 'open' },
            { id: 'closed', name: 'closed' },
            // { id: 'freshlyGroomed', name: 'freshly groomed' },
        ]} />
        <SelectInput source="display" allowEmpty={false} alwaysOn resettable choices={[
            { id: DisplayStatus.Display, name: 'Display' },
            { id: DisplayStatus.Hide, name: 'Hide' },
            { id: DisplayStatus.Scheduled, name: 'Scheduled'},
        ]} />
        {/*<Button variant="text" onClick={() => props.setFilters({})} label="Clear filters"/>*/}
    </Filter>
);

const PathPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const rowStyle = (record, index) => {
    const rowCSS = {};
    if (record.status === 'closed') {
        rowCSS['backgroundColor'] = '#ffeeee';
    }
    return rowCSS;
};

export const PathList = (props: any) => {
    const getDisplay = (record) => {
        if (record.display === DisplayStatus.Scheduled && record.timePeriod) {
            return `${startCase(toLower(record.display))} (${
                record.timePeriod.name
            })`;
        }
        return startCase(toLower(record.display));
    };

    return (
        <List
            {...props}
            perPage={25}
            pagination={<PathPagination />}
            bulkActionButtons={<PathBulkActionButtons />}
            filters={<PathFilter/>}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={PathEdit} rowStyle={rowStyle}>
                <TextField source="number" />
                <TextField source="name" />
                <DateField source="groomedAt" options={{ day: '2-digit', hour: '2-digit', minute: '2-digit', month: 'short' }}/>
                <TextField source="status" />
                <TextField source="pathCategory.name" label="Category" sortable={false}/>
                {/*<TextField source="area.name" label="Area" sortable={false}/>*/}

                <FunctionField
                    label="Display"
                    render={(record) => getDisplay(record)}
                />

                <ArrayField
                    source="areas"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField color="secondary" source="name" />
                    </SingleFieldList>
                </ArrayField>

                {/*<TextField source="length" label="Length (km)" />
                <TextField source="difficulty" />*/}
                <ArrayField
                    source="tags"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField clickable={false} source="name" color={'primary'} />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
    );
}

export default PathList;
