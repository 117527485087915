import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DateInput,
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  useGetList,
  useGetOne,
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const SettingsEdit = (props: any) => {
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    { page: 1, perPage: 100 },
    { field: "id", order: "ASC" },
    {}
  );
  const [selectedSummerId, setSelectedSummerId] = useState<number>(0);
  const [selectedWinterId, setSelectedWinterId] = useState<number>(0);
  const selectedSummerPeriod = GetTimePeriodRange(
    selectedSummerId,
    timePeriods.data || {}
  );
  const settings = useGetOne("settings", props.id);
  const selectedWinterPeriod = GetTimePeriodRange(
    selectedWinterId,
    timePeriods.data || {}
  );
  const classes = useStyles();

  useEffect(() => {
    setSelectedSummerId(settings?.data?.summerSeasonId);
    setSelectedWinterId(settings?.data?.winterSeasonId);
  }, [settings.loaded]);

  return (
    <Edit {...props}>
      <SimpleForm
        variant="outlined"
        margin="none"
        toolbar={<CustomToolbar noDelete noRedirect />}
      >
        <ReferenceInput
          label="Terms and Conditions"
          source="termsId"
          reference="terms"
          filter={{ status: "published" }}
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          label="Summer Season"
          source="summerSeasonId"
          reference="timeperiods"
          validate={[required()]}
          onChange={(e) => setSelectedSummerId(e.target.value)}
        >
          <SelectInput optionText="name" helperText={selectedSummerPeriod} />
        </ReferenceInput>
        <ReferenceInput
          label="Winter Season"
          source="winterSeasonId"
          reference="timeperiods"
          validate={[required()]}
          onChange={(e) => setSelectedWinterId(e.target.value)}
        >
          <SelectInput optionText="name" helperText={selectedWinterPeriod} />
        </ReferenceInput>

        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
