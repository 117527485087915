import * as React from "react";
import { Filter, TextInput } from "react-admin";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { InformationTagEdit } from "./information-tag.edit.component";

const InformationTagListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
  </Filter>
);

export const InformationTagList = (props: any) => {
  return (
    <List {...props} filters={<InformationTagListFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={InformationTagEdit}>
        <TextField source="name" />
        <DateField
          source="createdAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <DateField
          source="updatedAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
      </Datagrid>
    </List>
  );
};
