import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { Edit, SimpleForm, TextInput, NumberInput, DateInput, required, ReferenceArrayInput, SelectArrayInput, ReferenceInput, SelectInput, ImageField, ImageInput, minValue, maxValue, useGetList, useGetOne, Loading } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';
import { DisplayStatus } from "../../enums/display-status.enum";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";
import { validateDisplayStatus } from "../../services";

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const displayOptions = Object.keys(DisplayStatus).map(key => ({
    id: DisplayStatus[key],
    name: key,
}));

export const PoiEdit = (props: any) => {
    const classes = useStyles();
    const timePeriods = useGetList<TimePeriod>(
        "timeperiods",
        { page: 1, perPage: 100 },
        { field: "id", order: "ASC" },
        {}
    );
    const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
    const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
    const selectedTimePeriod = GetTimePeriodRange(
        selectedTimePeriodId,
        timePeriods.data || {}
    );
    const poi = useGetOne("pois", props.id);

    useEffect(() => {
      setSelectedTimePeriodId(poi?.data?.timePeriodId);
      setDisplayValue(poi?.data?.display);
    }, [poi.loaded]);

    if (!timePeriods.loaded && !poi.loaded) {
        return <Loading></Loading>;
    }
    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar/>} validate={validateDisplayStatus}>
                <TextInput source="name" validate={[required()]} formClassName={classes.inlineBlock}/>
                {/*<TextInput source="icon" />*/}
                <NumberInput source="latitude" validate={[required(), minValue(-90), maxValue(90)]} min={-90} max={90}/>
                <NumberInput source="longitude" validate={[required(), minValue(-180), maxValue(180)]} min={-180} max={180}/>
                <NumberInput source="elevation" validate={[required()]}/>
                <TextInput source="url" />
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <ReferenceInput label="Category" source="pointOfInterestCategoryId" reference="poicategories" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>
                <ReferenceArrayInput
                    label="Areas"
                    source="areas"
                    reference="areas"
                    target="id"
                    perPage={9999}
                    parse={ids => ids?.map(id => ({ id }))}
                    format={areas => areas?.map(b => b.id)}
                >
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <SelectInput
                    source="display"
                    choices={displayOptions}
                    onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
                    validate={[required()]}
                ></SelectInput>
                {displayValue === DisplayStatus.Scheduled && (
                    <ReferenceInput
                        label="Time period"
                        source="timePeriodId"
                        reference="timeperiods"
                        perPage={9999}
                        onChange={(e) => setSelectedTimePeriodId(e.target.value)}
                    >
                        <SelectInput
                            optionText="name"
                            helperText={selectedTimePeriod}
                        ></SelectInput>
                    </ReferenceInput>
                )}
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="fullUrl" title="title" />
                </ImageInput>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" formClassName={classes.inlineBlock}/>
                    <TextInput source="description_en" label="Description eng" rows={8} multiline={true} fullWidth={true} />
                </TranslationInputs>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}