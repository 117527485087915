import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput, SelectInput, required, email, minLength, regex } from 'react-admin';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const validatePhone = regex(/^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/, 'Must be a valid phone number format');

export const UserCreate = (props: any) => {
    const classes = useStyles();

    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none">
                <BooleanInput source="isActive" defaultValue={true} />
                <TextInput source="firstName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="lastName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="email" validate={[required(), email()]}/>
                <TextInput source="phone" validate={[required(), validatePhone]} />
                <TextInput source="password" validate={[required(), minLength(8)]}/>
                <SelectInput source="role" validate={[required()]} initialValue={'user'} choices={[
                    { id: 'user', name: 'User' },
                    { id: 'groomer', name: 'Groomer' },
                    { id: 'admin', name: 'Admin' },
                ]} />
            </SimpleForm>
        </Create>
    );
}