import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import TranslationInputs from "../../../components/translationInputs/translationInputs.component";
import { Status } from "../../../shared/enums/status.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const FraCreate = (props: any) => {
  const classes = useStyles();

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));

  return (
    <Create {...props}>
      <SimpleForm variant="outlined" margin="none">
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <SelectInput
          source="status"
          validate={[required()]}
          initialValue={"open"}
          choices={statusOptions}
        />
        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="areas"
          target="id"
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(areas) => areas?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" validate={[required()]} />
        </ReferenceArrayInput>
        <FileInput
          source="geojson"
          label="Geo-Json file"
          accept=".geojson"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>

        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="fullUrl" title="title" />
        </ImageInput>

        <TranslationInputs {...props}>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
      </SimpleForm>
    </Create>
  );
};
