import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  required,
  SelectInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  useGetOne,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";
import { MapConnection } from "./enums/map-connection.enum";
import { Recurrence } from "./enums/recurrence.enum";
import { Severity } from "./enums/severity.enum";
import { validateInformation } from "./services/validate-information.service";
import { startCase } from "lodash";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const InformationEdit = (props: any) => {
  const classes = useStyles();
  const information = useGetOne("information", props.id);
  const [mapConnectionValue, setMapConnectionValue] = useState(
    MapConnection.None
  );

  const mapConnectionOptions = Object.keys(MapConnection).map((key) => ({
    id: MapConnection[key],
    name: startCase(key),
  }));

  const statusOptions = Object.keys(PublishingStatus).map((key) => ({
    id: PublishingStatus[key],
    name: startCase(key),
  }));

  const severityOptions = Object.keys(Severity).map((key) => ({
    id: Severity[key],
    name: startCase(key),
  }));

  const recurrenceOptions = Object.keys(Recurrence).map((key) => ({
    id: Recurrence[key],
    name: startCase(key),
  }));

  useEffect(() => {
    setMapConnectionValue(information?.data?.mapConnection);
  }, [information.loaded]);

  return (
    <Edit {...props}>
      <SimpleForm
        variant="outlined"
        margin="none"
        toolbar={<CustomToolbar />}
        validate={validateInformation}
      >
        <SelectInput
          source="status"
          validate={[required()]}
          choices={statusOptions}
        />
        <SelectInput
          source="severity"
          validate={[required()]}
          choices={severityOptions}
        />
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
          validate={[required()]}
        />
        <DateTimeInput source="startAt" defaultValue={new Date()} />
        <DateTimeInput source="endAt" />
        <TextInput source="buttonText" />
        <TextInput source="buttonUrl" />

        <ReferenceArrayInput
          label="Tags"
          source="tags"
          reference="informationtags"
          target="id"
          allowEmpty
          parse={(ids) => ids?.map((id) => ({ id }))}
          format={(tags) => tags?.map((b) => b.id)}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <SelectInput
          source="mapConnection"
          choices={mapConnectionOptions}
          onChange={(e) =>
            setMapConnectionValue(e.target.value as MapConnection)
          }
          validate={[required()]}
        ></SelectInput>
        {mapConnectionValue === MapConnection.PointOfInterest && (
          <ReferenceInput
            label="Point of Interest"
            source="pointOfInterestId"
            reference="pois"
          >
            <AutocompleteInput
              optionText="name"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {mapConnectionValue === MapConnection.AreaOfInterest && (
          <ReferenceInput
            label="Area of Interest"
            source="areaOfInterestId"
            reference="aois"
          >
            <AutocompleteInput
              optionText="name"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {(mapConnectionValue === MapConnection.PointOfInterest ||
          mapConnectionValue === MapConnection.AreaOfInterest) && (
          <NumberInput source="radius" label="radius (m)" />
        )}
        <SelectInput
          source="recurrence"
          validate={[required()]}
          choices={recurrenceOptions}
        />
        <TranslationInputs {...props}>
          <TextInput source="title_en" label="Title eng" fullWidth />
          <TextInput source="buttonText_en" label="ButtonText eng" fullWidth />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
