import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { BulkDeleteWithConfirmButton, Filter, NullableBooleanInput, TextInput } from 'react-admin';
import { List, Datagrid, TextField, EmailField, BooleanField, DateField } from 'react-admin';
import { UserEdit } from "./user.edit.component";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const UserBulkActionButtons = (props:any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isActive, setIsActive] = React.useState('');

    React.useEffect(() => {
        setIsActive('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleChange = (event: any) => {
        setIsActive(event.target.value);
    };

    return (
        <Fragment>
            <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">Set active</InputLabel>
                <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={isActive}
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Yes</MenuItem>
                    <MenuItem value={20}>No</MenuItem>
                </Select>
                {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
            </FormControl>
            <BulkDeleteWithConfirmButton {...props} />
        </Fragment>
    );
}

const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" variant={'outlined'} source="q" alwaysOn resettable/>
        <NullableBooleanInput label="Is Active" source="isActive" resettable allowEmpty={false} nullLabel="All"/>
        {/*<ReferenceInput
            label="User"
            source="userId"
            reference="users"
            allowEmpty
        >
            <SelectInput optionText="email" />
        </ReferenceInput>*/}
    </Filter>
);

export const UserList = (props: any) => {
    return (
        <>
            <List
                {...props}
                exporter={false}
                bulkActionButtons={<UserBulkActionButtons/>}
                filters={<UserFilter/>}
            >
                <Datagrid rowClick="edit" expand={UserEdit}>
                    <TextField source="id" />
                    <BooleanField source="isActive" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <EmailField source="email" />
                    {/*<TextField source="services" />
                    <TextField source="role" />*/}
                    <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                    <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                    {/*<ArrayField source="userProducts"><SingleFieldList><ChipField source="id" /></SingleFieldList></ArrayField>*/}
                </Datagrid>
            </List>
        </>
    );
}