import * as React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  getStatusForArrayInput,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { TermsEdit } from "./terms.edit.component";
import { startCase } from "lodash";

const TermsListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
  </Filter>
);

export const TermsList = (props: any) => {
  const getStatus = (record) => {
    if (record.status === "published") {
      return "Ready";
    }
    return startCase(record.status);
  };
  return (
    <List {...props} filters={<TermsListFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={TermsEdit}>
        <TextField source="name" />
        <FunctionField label="Status" render={(record) => getStatus(record)} />
        <DateField
          source="updatedAt"
          options={{
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }}
        />
      </Datagrid>
    </List>
  );
};
