import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Edit, SimpleForm, TextInput, DateInput, required } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const AreaEdit = (props: any) => {
    const classes = useStyles();

    return (
        <Edit {...props}>
            <SimpleForm variant="outlined" margin="none" toolbar={<CustomToolbar/>}>
                <TextInput source="name" validate={[required()]} fullWidth/>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                </TranslationInputs>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}