import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";

export const AreaCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm variant="outlined" margin="none">
                <TextInput source="name" validate={[required()]} fullWidth/>
                <TranslationInputs {...props}>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                </TranslationInputs>
            </SimpleForm>
        </Create>
    );
}